import ApiService from "@/services/api.service";


// Authentication state
const state = {}

// Authentication getter
const getters = {}

// Authentication action
const actions = {
    async register(context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("auth/register/", credentials)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    token_verification(context, payload) {
        return new Promise((resolve, reject) => {
            let token = payload['token']
            let status = payload['status']
            ApiService.post(`auth/token_verification/${token}/`, {'action': status})
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    accept_invite(context, payload) {
        return new Promise((resolve, reject) => {
            let token = payload['token']
            ApiService.post(`auth/token_verification/${token}/`, payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    login(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`auth/login/`, payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    forget_password(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`auth/forget_password/`, payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    create_password(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`auth/create_new_password/`, payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
}

//Authentication mutation
const mutations = {}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
