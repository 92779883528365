import ApiService from "../services/api.service";


// user state
const state = {
    customers: {results: [], total_pages: 0},
    total_pages: 1
}

// user getter
const getters = {
    GET_CUSTOMERS(state) {
        return state.customers
    },
}

// user action
const actions = {
    getCustomers(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/customers/?${payload.data}`,)
                .then(response => {
                    response.data.results.forEach(res => {
                        res.checked = false
                    })
                    context.commit('UPDATE_CUSTOMERS', {
                        results: response.data.results,
                        total_pages: response.data.total
                    })
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createCustomer(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.post(`site/merchant/${payload.merchant}/customers/`, payload.data)
                .then(response => {
                    context.commit('ADD_CUSTOMER', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateCustomer(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.patch(`site/merchant/${payload.merchant}/customers/${payload.code}/`, payload.data)
                .then(response => {
                    context.commit('UPDATE_SINGLE_CUSTOMER', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteCustomer(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.delete(`site/merchant/${payload.merchant}/customers/${payload.code}/`)
                .then(response => {
                    context.commit('DELETE_CUSTOMER', payload)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    sendPortalInvitation(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.post(`site/merchant/${payload.merchant}/customers/invitation/send/`, payload.data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
}

//user mutation
const mutations = {
    UPDATE_CUSTOMERS(state, payload) {
        state.customers.results = payload.results
        state.customers.total_pages = payload.total_pages
    },
    UPDATE_SINGLE_CUSTOMER(state, payload) {
        const customerIndex = state.customers.results.findIndex(item => item.code === payload.code)
        const resultCopy = JSON.parse(JSON.stringify(state.customers))
        if (customerIndex > -1) {
            resultCopy.results[customerIndex] = payload
            state.customers = resultCopy
        }
    },
    ADD_CUSTOMER(state, payload) {
        const resultCopy = JSON.parse(JSON.stringify(state.customers))
        resultCopy.results.unshift(payload)
        state.customers = resultCopy
    },
    DELETE_CUSTOMER(state, payload) {
        const results = JSON.parse(JSON.stringify(state.customers))
        const resultCopy = results.results.filter(item => item.code !== payload.code)
        state.customers = {
            results: resultCopy,
            total_pages: state.total_pages
        }
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};