import ApiService from "./../services/api.service";
// state
const state = {
    withdrawals: {results: []},
    withdraw: {}
}
// withdraw getter
const getters = {
    GET_WITHDRAWALS(state) {
        return state.withdrawals
    },
    GET_WITHDRAWAL(state) {
        return state.withdraw
    }
}
// action
const actions = {
    getWithdrawals(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`/transaction/withdraw/${payload['params']}`)
                .then(response => {
                    context.commit('UPDATE_WITHDRAWS', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createWithdrawal(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.post(`/transaction/withdraw/`, payload)
                .then(response => {
                    // context.commit('UPDATE_WITHDRAWS', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getWithdrawal(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`/transaction/withdraw/${payload['id']}`)
                .then(response => {
                    context.commit('UPDATE_SINGLE_WITHDRAW', response.data.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
}

// mutation
const mutations = {
    UPDATE_WITHDRAWS(state, payload) {
        state.withdrawals = payload
    },
    UPDATE_SINGLE_WITHDRAW(state, payload) {
        state.withdraw = payload
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
