import ApiService from "./../services/api.service";
// state
const state = {
    tariffs: {results: [], total_pages: 0},
}
// general getter
const getters = {
    GET_TARIFFS(state) {
        return state.tariffs
    },
}
// action
const actions = {
    getTariffs(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/${payload.meter_code}/tariff/?is_paging=false`,)
                .then(response => {
                    context.commit('UPDATE_TARIFFS', {
                        results: response.data.results,
                        total_pages: response.data.total
                    })
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getTariff(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/${payload.meter_code}/tariff/${payload.code}/`,)
                .then(response => {
                    context.commit('UPDATE_TARIFF', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createTariff(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.post(`site/merchant/${payload.merchant}/meters/${payload.meter_code}/tariff/`, payload.data)
                .then(response => {
                    context.commit('ADD_TARIFF', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateTariff(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.patch(`site/merchant/${payload.merchant}/meters/${payload.meter_code}/tariff/${payload.code}/`, payload.data)
                .then(response => {
                    context.commit('UPDATE_SINGLE_TARIFF', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteTariff(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.delete(`site/merchant/${payload.merchant}/meters/${payload.meter_code}/tariff/${payload.code}/`)
                .then(response => {
                    context.commit('DELETE_TARIFF', payload)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

}

// mutation
const mutations = {
    UPDATE_TARIFFS(state, payload) {
        state.tariffs.results = payload.results
        state.tariffs.total_pages = payload.total_pages
    },
    UPDATE_SINGLE_TARIFF(state, payload) {
        const tariffIndex = state.tariffs.results.findIndex(item => item.code === payload.code)
        const resultCopy = JSON.parse(JSON.stringify(state.tariffs))
        if (tariffIndex > -1) {
            resultCopy.results[tariffIndex] = payload
            state.tariffs = resultCopy
        }
    },
    ADD_TARIFF(state, payload) {
        const resultCopy = JSON.parse(JSON.stringify(state.tariffs))
        resultCopy.results.unshift(payload)
        state.tariffs = resultCopy
    },
    DELETE_TARIFF(state, payload) {
        const results = JSON.parse(JSON.stringify(state.tariffs))
        const resultCopy = results.results.filter(item => item.code !== payload.code)
        state.tariffs = {
            results: resultCopy,
            total_pages: state.total_pages
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

