import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from "vuex-persist";
import auth from './auth.module'
import general from './general.module'
import user from './user.module'
import device from './device.module'
import withdraw from './withdraw.module'
import statistics from './statistics.module'
import transactions from './transaction.module'
import customers from "./customer.module"
import meter from "./meter.module"
import meter_tariff from "./meter_tariff.module"
import meter_reading from "./meter_reading.module"
import meter_transaction from "./meter_transaction.module"

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage
});
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth: auth,
        general: general,
        user: user,
        device: device,
        withdraw: withdraw,
        statistics: statistics,
        transactions: transactions,
        customers: customers,
        meter,
        meter_tariff,
        meter_reading,
        meter_transaction
    },
    plugins: [vuexLocal.plugin]
});
