//Component import

export const routes = [
    {
        path: "/login",
        name: "login",
        meta: {
            title: 'Sign in to your dashboard'
        },
        component: () => import(/* webpackChunkName: "chunck" */ './main/auth/login'),
    },
    {
        path: "/reset",
        name: "reset",
        meta: {
            title: 'Cardfare Merchant dashboard'
        },
        component: () => import(/* webpackChunkName: "chunck" */ './main/auth/ForgetPassword'),
    },
    {
        path: "/reset-password/:token",
        name: "reset-password",
        meta: {
            title: 'Cardfare Merchant dashboard'
        },
        component: () => import(/* webpackChunkName: "chunck" */ './main/auth/ResetPassword'),
    },
    {
        path: "/register",
        name: "register",
        component: () => import(/* webpackChunkName: "chunck" */ './main/auth/register'),
    },
    {
        path: "/email-verification/:token",
        name: "email_verification",
        props: true,
        component: () => import(/* webpackChunkName: "chunck" */ './main/auth/email_verification'),
    },
    {
        path: "/bank-verification/:token",
        name: "bank_verification",
        props: true,
        component: () => import(/* webpackChunkName: "chunck" */ './main/auth/bank_verification'),
    },
    {
        path: "/portal/overview/",
        name: "portal-overview",
        meta: {
            requireAuth: true
        },
        component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/PortalOverview/IndexView.vue'),
    },
    {
        path: "",
        component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/MainComponent.vue'),
        meta: {
            requireAuth: true,
            title: 'Dashboard | Customer section',
        },
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/dashboard'),
            },
            {
                path: "/get-started",
                name: "get-started",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/get-started'),
            },
            {
                path: "/setting",
                name: "settings",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/setting'),
            },
            {
                path: "/devices",
                name: "devices",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/pos/dashboard'),
            },
            {
                path: "/device/add-device",
                name: "add-devices",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/pos/CreateDevice'),
            },
            {
                path: "/device/setting/:id",
                name: "device-setting",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/pos/DeviceSetting'),
            },
            {
                path: "/attendance",
                name: "attendance",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/attendance/Dashboard'),
            },
            {
                path: "/withdrawal",
                name: "withdrawal",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/withdraw/dashboard'),
            },
            {
                path: "/transactions",
                name: "transaction",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/transaction/dashboard'),
            },
        ]
    },
    {
        path: "",
        component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/MeterPortal/MainMeterComponent.vue'),
        meta: {
            requireAuth: true,
            title: 'Meter Management',
        },
        children: [
            {
                path: "meter/customers",
                name: "meter-customer",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/customers/dashboard'),
            },
            {
                path: "/meter/overview",
                name: "meter-overview",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/MeterPortal/Overview'),
            },
            {
                path: "/meter/:code/:section",
                name: "meter-detail-overviews",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/MeterPortal/meter/MeterOverview'),
            },
            {
                path: "/meter/dashboard",
                name: "meter-dashboard",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/MeterPortal/meter/dashboard'),
            },
            {
                path: "/meter/tariff",
                name: "meter-tariff",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/MeterPortal/tariff/dashboard'),
            },
            {
                path: "/meter/setting",
                name: "meter-settings",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/setting'),
            },
            {
                path: "/meter/transactions",
                name: "meter-transactions",
                component: () => import(/* webpackChunkName: "chunck" */ './main/merchant/MeterPortal/transactions/dashboard'),
            },
        ]

    },
    {
        path: "*",
        redirect: {
            name: 'login'
        }
    },
];
