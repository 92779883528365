// action
import ApiService from "../services/api.service";

const actions = {
    getGraph() {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`transaction/statistic/income/`)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
}
export default {
    namespaced: true,
    actions
};