import JwtService from "./../services/jwt.service";
import ApiService from "../services/api.service";


// user state
const state = {
    user: {},
    merchant: {},
    is_authenticated: false,
    section_name: "",
    token: '',
    wallet: {},
}

// user getter
const getters = {
    GET_USER(state) {
        return state.user
    },
    GET_MERCHANT_INFO(state) {
        return state.merchant
    },
    IS_AUTHENTICATED(state) {
        return state.is_authenticated
    },
    GET_WALLET(state) {
        return state.wallet
    }
}

// user action
const actions = {
    async UPDATE_ACCOUNT(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.put(`/user/${payload.id}/`, payload.data)
                .then(response => {
                    context.commit('UPDATE_USER', {'user': response.data.data});
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async UPDATE_MERCHANT(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.put(`/merchant/${payload.username}/`, payload.data)
                .then(response => {
                    context.commit('UPDATE_MERCHANT_ACCOUNT', {'data': response.data.data});
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async UPDATE_MERCHANT_BANK_INFO(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.put(`/merchant/${payload.username}/bank_info/`, payload.data)
                .then(response => {
                    context.commit('UPDATE_MERCHANT_ACCOUNT', {'data': response.data.data});
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async UPDATE_SECURITY_KEY(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.put(`/merchant/${payload.username}/security/`, payload.data)
                .then(response => {
                    context.commit('UPDATE_MERCHANT_ACCOUNT', {'data': response.data.data});
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async UPDATE_PASSWORD(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.put(`/user/update_password/`, payload.data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async GET_MERCHANT(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`/merchant/${payload.username}/`)
                .then(response => {
                    context.commit('UPDATE_MERCHANT_ACCOUNT', {'data': response.data.data});
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async GET_ACCOUNT(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`/user/${payload.id}/`)
                .then(response => {
                    context.commit('UPDATE_USER', {'user': response.data.data});
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async GET_ACCOUNT_WALLET(context) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`/user/wallet/`)
                .then(response => {
                    context.commit('UPDATE_WALLET', {'data': response.data.data});
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    LOGOUT(context) {
        context.commit('PURGE_UTH')
    },
}

//user mutation
const mutations = {
    UPDATE_AUTH(state, payload) {
        state.is_authenticated = true;
        state.user = payload.user;
        state.merchant = payload.user?.merchant;
        state.token = payload.token;
        JwtService.saveToken(payload.token);
    },
    UPDATE_USER(state, payload) {
        state.user = payload.user;
    },
    UPDATE_MERCHANT_ACCOUNT(state, payload) {
        state.merchant = payload.data;
    },
    UPDATE_WALLET(state, payload) {
        state.wallet = payload.data;
    },
    PURGE_AUTH(state) {
        state.is_authenticated = false;
        state.user = {};
        state.merchant = {};
        state.token = '';
        JwtService.destroyToken();
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};