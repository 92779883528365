import ApiService from "./../services/api.service";
// state
const state = {
    devices: {data: []},
    device: {}
}
// general getter
const getters = {
    GET_DEVICES(state) {
        return state.devices
    },
    GET_DEVICE(state) {
        return state.device
    }
}
// action
const actions = {
    getDevices(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`/transport/device/${payload['params']}`)
                .then(response => {
                    context.commit('UPDATE_DEVICE', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getDevice(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`/transport/device/${payload['id']}`)
                .then(response => {
                    context.commit('UPDATE_SINGLE_DEVICE', response.data.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    verifyDevice(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`/transport/device/verify/${payload['serial_number']}`)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    createDevice(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.post(`/transport/device/`, payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteDevice(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.delete(`/transport/device/${payload.id}`)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updateDevice(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.put(`/transport/device/${payload.id}/`, payload.data)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
}

// mutation
const mutations = {
    UPDATE_DEVICE(state, payload) {
        state.devices = payload
    },
    UPDATE_SINGLE_DEVICE(state, payload) {
        state.device = payload
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

