import ApiService from "./../services/api.service";


// transaction state
const state = {
    transactions: {results: [], total_pages: 0},
    total_pages: 0
}

// transaction getter
const getters = {
    GET_TRANSACTIONS(state) {
        return state.transactions
    },
    GET_TRANSACTION_PAGE(state) {
        return state.total_pages
    }
}

// transaction action
const actions = {
    getTransaction(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`transaction/?${payload.data}`,)
                .then(response => {
                    context.commit('UPDATE_TRANSACTION', {
                        results: response.data.results,
                        total_pages: response.data.total_pages
                    })
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}

//transaction mutation
const mutations = {
    UPDATE_TRANSACTION(state, payload) {
        state.transactions.results = payload.results
        state.transactions.total_pages = payload.total_pages
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};