import Vue from 'vue'
import App from './App.vue'
import store from "./store";
import VueRouter from "vue-router";
import {routes} from "./routes";
import {
    Input,
    DatePicker,
    Drawer,
    Carousel,
    CarouselItem,
    InfiniteScroll,
    ColorPicker,
    Dialog,
    Button,
    Slider,
    InputNumber,
    Tooltip,
    Upload,
    Loading,
    Select,
    OptionGroup,
    Option,
    MessageBox,
    Notification,
    Card,
    Row,
    Col,
    Image, Pagination, Tabs, TabPane, ButtonGroup, Skeleton, SkeletonItem, Checkbox,
    Dropdown, DropdownMenu, DropdownItem
} from 'element-ui'
import CxltToastr from 'cxlt-vue2-toastr'
import ApiService from "./services/api.service";
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'

Vue.config.productionTip = false
var toastrConfigs = {
    position: 'top right',
    showDuration: 2000
}
Vue.use(CxltToastr, toastrConfigs)
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'
import 'element-ui/lib/theme-chalk/index.css'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import VueApexCharts from 'vue-apexcharts'
import './index.css'
import './assets/style.css'

locale.use(lang)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueRouter);
const routerPush = VueRouter.prototype.push
ApiService.init();
window.Bus = new Vue()

Vue.use(Loading.directive)
Vue.use(InfiniteScroll)
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Checkbox)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(InputNumber)
Vue.use(Drawer)
Vue.use(Select)
Vue.use(ButtonGroup)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(OptionGroup)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(ColorPicker)
Vue.use(Upload)
Vue.use(Slider)
Vue.use(Option)
Vue.use(Button)
Vue.use(Tooltip)
Vue.use(Image)
Vue.use(Input)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Pagination)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)


Vue.prototype.$loading = Loading.service
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$notify = Notification
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(() => console.log('terminated'))
}

VueRouter.prototype.replace = function push(location) {
    return routerPush.call(this, location).catch(() => console.log('terminated'))
}

const router = new VueRouter({
    mode: "history",
    routes,
    linkExactActiveClass: "active",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {selector: to.hash};
        }
        return {x: 0, y: 0};
    },
});
const auth = {
    loggedIn() {
        return store.getters['user/IS_AUTHENTICATED']
    }
}

router.beforeEach((to, from, next) => {
    window.document.title = to.meta && to.meta?.title ? to.meta.title : 'Card Fare | Your personal online Iot management software.'
    if (to.matched.some(record => record.meta.requireAuth)) {
        if (!auth.loggedIn()) {
            next({
                name: 'login'
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
    next()
})

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
