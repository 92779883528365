import ApiService from "./../services/api.service";
// state
const state = {}
// general getter
const getters = {}
// action
const actions = {
    getLatestReading(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/${payload.meter_code}/reading/latest/`,)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getMeterReadings(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get(`site/merchant/${payload.merchant}/meters/${payload.meter_code}/readings/${payload.data}`,)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
}

// mutation
const mutations = {}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

