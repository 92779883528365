<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import isScreen from "./helpers/screenHelper";

export default {
  name: 'App',
  methods: {
    resize() {
      if (isScreen('xs') || isScreen('sm') || isScreen('md')) {
        this.$store.commit('general/SET_SIDE_BAR', {'status': false})
      } else {
        this.$store.commit('general/SET_SIDE_BAR', {'status': true})
      }
    },
  },
  created() {
    this.resize()
  },
  mounted() {
    window.addEventListener('resize', this.resize);
  },

}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}
</style>
