import ApiService from "./../services/api.service";


// general state
const state = {
    country: {data: []},
    states: [],
    sideBar: false,
    pseudoAuth: {
        email: '',
        password: ''
    }
}

// general getter
const getters = {
    GET_COUNTRY(state) {
        return state.country
    },
    GET_STATE(state) {
        return state.states
    },
    getSideBar(state) {
        return state.sideBar
    },
    getpseudoAuth(state) {
        return state.pseudoAuth
    },
}

// general action
const actions = {
    getCountry(context) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/miscellaneous/country/`,)
                .then(response => {
                    context.commit('UPDATE_COUNTRY', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getState(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/miscellaneous/province/?code=${payload['code']}`,)
                .then(response => {
                    context.commit('UPDATE_STATE', response.data.province)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getBankList() {
        return new Promise((resolve, reject) => {
            ApiService.get(`/miscellaneous/banks/`)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    verifyAccountNumber(context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/miscellaneous/bank/bin/`, payload)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
}

//general mutation
const mutations = {
    UPDATE_COUNTRY(state, payload) {
        state.country = payload
    },
    UPDATE_STATE(state, payload) {
        state.states = payload
    },
    SET_SIDE_BAR(state, payload) {
        state.sideBar = payload['status']
    },
    SET_PSEUDO_AUTH(state, payload) {
        state.pseudoAuth = payload
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
